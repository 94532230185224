import Work1 from '../../assets/work-1.png';
import Work2 from '../../assets/work-2.png'


const Menu = [
    {
      id: 1,
      image: Work1,
      title: "Portfolio",
      category: "Web App",
      link:"https://sbdev.me"
    },{
      id:2,
      image: Work2,
      title: "NetflixGPT",
      category: "Web App",
      link:"https://netflix-gpt-3kbp.vercel.app/"
    }

  ];
  
export default Menu